import { getEnvironment } from "@/environment/environment";

type SanityDatasets = "bruktbil-development" | "bruktbil-production";
type SanityMainDatasets = "master-development" | "master-production";

export const getSanityDataset = (): SanityDatasets => {
  const env = getEnvironment();

  // TODO: Setup stage dataset in Sanity
  if (env === "prod") {
    return "bruktbil-production";
  }

  return "bruktbil-development";
};

export const getSanityMainDataset = (): SanityMainDatasets => {
  const env = getEnvironment();

  if (env === "prod") {
    return "master-production";
  }

  return "master-development";
};
