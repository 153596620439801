import { styled } from "styled-components";
import {
  GhostButton,
  InfoCard,
  Subtitle,
  Description,
} from "@moller/design-system";
import { useTradeInContext } from "@/components/tradeIn/tradeInProvider";
import { TradeInContent } from "@/components/tradeIn/tradeInContent";
import { TradeInModal } from "@/components/tradeIn/tradeInModal";
import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";

export const Title = styled.div`
  ${Subtitle.selector} {
    font-size: var(--moller-type-small-subtitle-font-size);
    margin: 0;
  }
`;

export const DescriptionText = styled(Description)`
  font-size: var(--moller-type-small-description-font-size);
  color: var(--moller-color-on-disabled);
  margin: var(--moller-spacing-base) 0 var(--moller-spacing-xs);
`;

export const TradeInCard = ({
  departmentSlug,
}: {
  departmentSlug?: string;
}) => {
  const { tradeInPrice, setIsShowingTradeInModal } = useTradeInContext();
  const isTradeInEnabled = !departmentSlug;

  useEffect(() => {
    if (isTradeInEnabled && !tradeInPrice) {
      datadogRum.addAction("Sees trade-in button in overview page");
    }
  }, [isTradeInEnabled, tradeInPrice]);

  if (!isTradeInEnabled) {
    return;
  }
  return (
    <>
      <InfoCard>
        {tradeInPrice ? (
          <TradeInContent context="overview" />
        ) : (
          <>
            <Title>
              <Subtitle $as="h2">Har du en bil å bytte inn?</Subtitle>
            </Title>
            <DescriptionText>
              Skriv inn verdien på bilen din og se priser med innbytte.
            </DescriptionText>
            <GhostButton
              onClick={() => {
                setIsShowingTradeInModal(true);
                datadogRum.addAction(
                  "Clicks button to add trade-in car in overview page",
                );
              }}
            >
              Legg til innbytte
            </GhostButton>
          </>
        )}
      </InfoCard>
      <TradeInModal context="overview" />
    </>
  );
};
