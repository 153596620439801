import { urlForImage } from "@/data/sanity/utils/image";
import type { Image as ImageType } from "sanity";
import NextImage from "next/image";
import {
  SanityImageRect,
  SanityImageSource,
} from "@sanity/image-url/lib/types/types";

// Generic image component
interface Props {
  className?: string;
  image: ImageType | undefined;
  imageSrc?: string; // TODO: Remove this when we remove feature flag for Sanity campaigns
  alt: string;
  sanitySource: "main" | "local"; // TODO: Is this ok or problematic for the usage?
  height: number;
  width: number;
  priority?: boolean;
  quality?: number;
  rect?: SanityImageRect;
}

export const Image = ({
  className,
  image,
  imageSrc,
  alt,
  sanitySource,
  height,
  width,
  priority,
  quality = 85,
  rect: crop,
}: Props) => {
  if (!image && imageSrc) {
    // temporary solution while campaign is behind feature flag and might be hardcoded
    return (
      <NextImage
        className={className}
        width={width}
        height={height}
        src={imageSrc}
        alt={alt}
        priority={priority}
        quality={quality}
      />
    );
  }

  if (!image) {
    return null;
  }

  const src = urlForImage(sanitySource, image, width, height, crop)
    .quality(quality)
    ?.url();

  return (
    <NextImage
      className={className}
      width={width}
      height={height}
      src={src}
      // fill={true}
      // style={{ objectFit: "cover" }}
      // sizes="100vw, 100vw"
      // sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
      alt={alt}
      priority={priority}
      quality={quality}
    />
  );
};
