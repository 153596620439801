import type { Image } from "sanity";
import createImageUrlBuilder from "@sanity/image-url";
import { getSanityDataset, getSanityMainDataset } from "../datasets";
import { SanityImageRect } from "@sanity/image-url/lib/types/types";

const imageBuilder = createImageUrlBuilder({
  projectId: "l0whhinj", // TODO: Move this to a `sanity.constants.ts` file?
  dataset: getSanityDataset(),
});

const sanityMainImageBuilder = createImageUrlBuilder({
  projectId: "l0whhinj", // TODO: Move this to a `sanity.constants.ts` file?
  dataset: getSanityMainDataset(),
});

// Need to know which dataset the image comes from... (i. e. Sanity Main or "local" Sanity)
export const urlForImage = (
  sanitySource: "main" | "local",
  image: Image,
  width: number,
  height: number,
  crop?: SanityImageRect,
) => {
  const imageUrlBuilder =
    sanitySource == "main" ? sanityMainImageBuilder : imageBuilder;

  return crop
    ? imageUrlBuilder
        ?.image(image)
        .rect(crop.left, crop.top, crop.width, crop.height)
        .auto("format")
        .fit("max")
    : imageUrlBuilder
        ?.image(image)
        .width(width)
        .height(height)
        .auto("format")
        .fit("max");
};
