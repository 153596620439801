import { styled } from "styled-components";
import { Lozenge } from "@moller/design-system";
import Image, { StaticImageData } from "next/image";
import React, { useState } from "react";
import defaultThumbnail from "@/public/images/defaultCar-thumbnail.jpg";

const ImageDiv = styled.div`
  position: relative;
  display: block;
  padding-top: 56.25%; // define height of image relative to its width
`;

const SoldBanner = styled.div`
  position: absolute;
  bottom: var(--moller-spacing-xs);
  left: var(--moller-spacing-xs);

  p {
    text-transform: capitalize;
  }
`;

type props = {
  thumbnailImageUrl: string | StaticImageData;
  isSold: boolean;
  width?: number;
  height?: number;
};

const CarThumbnailImage = ({
  thumbnailImageUrl,
  width,
  height,
  isSold,
}: props) => {
  const [isValidUrl, setIsValidUrl] = useState<boolean>(true);

  return (
    <ImageDiv>
      <Image
        src={isValidUrl ? thumbnailImageUrl : defaultThumbnail}
        alt=""
        loader={(img) => img.src}
        unoptimized={true}
        width={width}
        height={height}
        onError={() => setIsValidUrl(false)}
        fill={!(width || height)}
        sizes={width || height ? undefined : "100%, 100%"}
        style={{ objectFit: "cover" }}
      />
      {isSold && (
        <SoldBanner>
          <Lozenge colorVariant="warning">
            <p>Solgt</p>
          </Lozenge>
        </SoldBanner>
      )}
    </ImageDiv>
  );
};

export default CarThumbnailImage;
