import {
  CheckboxFilter,
  FilterLabel,
} from "@/components/filters/types/filters.types";

export const translateFilter = (filter: CheckboxFilter) => {
  if (filter.kind === "Fuel") {
    switch (filter.name) {
      case "Diesel":
        return "Diesel";
      case "Gasolin":
        return "Bensin";
      case "GasolinAndElectricity":
        return "Bensin/Elektrisk";
      case "DieselAndElectricity":
        return "Diesel/Elektrisk";
      case "Electricity":
        return "Elektrisk";
    }
  }

  if (filter.kind === "Equipment") {
    switch (filter.name) {
      case "TowHitch":
        return "Tilhengerfeste";
    }
  }
};

export const translateFilterLabels = (filterLabel: FilterLabel) => {
  switch (filterLabel) {
    case "BrandsWithModels":
      return "Merke og modell";
    case "Price":
      return "Pris";
    case "ModelYear":
      return "Årsmodell";
    case "Fuel":
      return "Drivstoff";
    case "Mileage":
      return "Kilometerstand";
    case "Departments":
      return "Velg utsalgssted";
    case "Equipment":
      return "Utstyr";
    case "SalesType":
      return "Salgsform";
  }
};
