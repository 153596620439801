"use client";

import { css, styled } from "styled-components";
import React from "react";
import { getThemeFromDealerName } from "../../design-system/ThemeProvider";

const Badge = styled.div<{ $isMollerBrand: boolean }>`
  ${(props) => css`
    width: fit-content;
    background-color: ${props.$isMollerBrand
      ? "var(--moller-color-tertiary)"
      : "var(--moller-palette-warning-60)"};
    display: flex;
    padding: var(--moller-size-base) var(--moller-size-xs);
    align-items: center;
    border-radius: var(--moller-radius-input);

    p {
      font-weight: 500;
      font-size: var(--moller-typeface-size-s);
    }
  `}
`;

export const CampaignBadge = ({
  dealerGroupName,
}: {
  dealerGroupName: string;
}) => {
  const theme = () => getThemeFromDealerName(dealerGroupName);

  return (
    <Badge $isMollerBrand={theme() === "mollerbil"}>
      <p>Kampanje</p>
    </Badge>
  );
};
