"use client";

import { useTradeInContext } from "@/components/tradeIn/tradeInProvider";
import { numberWithSpaces } from "@/utils/numberWithSpacing";
import {
  Subtitle,
  OverlineText,
  IconButton,
  breakpointTablet,
} from "@moller/design-system";
import React from "react";
import { styled } from "styled-components";
import { WishesValuationCard } from "@/components/tradeIn/wishesValuationCard";
import { Switch } from "@/components/tradeIn/Switch";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: var(--moller-spacing-xs);
  flex-wrap: wrap;
`;

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--moller-spacing-base);
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--moller-spacing-xxs);
`;

const SwitchLabel = styled.p`
  display: flex;

  @media screen and (max-width: ${breakpointTablet}) {
    display: none;
  }
`;

export const TradeInContent = ({
  context,
  isDealerMoller = true,
  showDesktopSwitchLabel = false,
}: {
  context: "car" | "overview";
  isDealerMoller?: boolean;
  showDesktopSwitchLabel?: boolean;
}) => {
  const {
    isTradeInPricingOn,
    setTradeInPricingOn,
    tradeInPrice,
    setIsShowingTradeInModal,
  } = useTradeInContext();
  const priceString = `${numberWithSpaces(tradeInPrice)} kr`;

  return (
    <>
      <Wrapper>
        <div>
          <OverlineText>Innbyttepris</OverlineText>
          <PriceWrapper>
            <Subtitle>{priceString}</Subtitle>
            <IconButton
              icon="edit"
              label="Rediger"
              onClick={() => setIsShowingTradeInModal(true)}
            />
          </PriceWrapper>
        </div>
        <SwitchWrapper>
          {showDesktopSwitchLabel && (
            <SwitchLabel>Vis pris med innbytte</SwitchLabel>
          )}
          <Switch
            checked={isTradeInPricingOn}
            onChange={() => setTradeInPricingOn(!isTradeInPricingOn)}
          />
        </SwitchWrapper>
      </Wrapper>
      {isTradeInPricingOn && (
        <WishesValuationCard
          context={context}
          isDealerMoller={isDealerMoller}
          minified
        />
      )}
    </>
  );
};
