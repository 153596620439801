"use client";

import { css, styled } from "styled-components";
import {
  GhostButton,
  Icon,
  breakpointTablet,
  Subtitle,
} from "@moller/design-system";
import { datadogRum } from "@datadog/browser-rum";
import { getValuationUrl } from "@/environment/environment";

const Card = styled.div<{ $isDealerMoller: boolean }>`
  ${(props) => css`
    padding: var(--moller-spacing-m);
    border-radius: var(--moller-border-radius-large);
    margin: var(--moller-spacing-xxl) 0;
    background-color: var(
      ${props.$isDealerMoller
        ? "--moller-color-primary-container"
        : "--moller-color-info-container"}
    );
    color: var(
      ${props.$isDealerMoller
        ? "--moller-color-on-primary-container"
        : "--moller-color-on-info-container"}
    );
  `}
`;

const MinifiedCard = styled.div<{ $isDealerMoller: boolean }>`
  ${(props) => css`
    padding: var(--moller-spacing-xs) var(--moller-spacing-s);
    border-radius: var(--moller-radius-card);
    margin-top: var(--moller-spacing-s);
    position: relative;
    background-color: var(
      ${props.$isDealerMoller
        ? "--moller-color-primary-container"
        : "--moller-color-info-container"}
    );
    color: var(
      ${props.$isDealerMoller
        ? "--moller-color-on-primary-container"
        : "--moller-color-on-info-container"}
    );

    @media screen and (min-width: ${breakpointTablet}) {
      width: fit-content;
    }

    &:after {
      content: "";
      position: absolute;
      height: 26px;
      width: 26px;
      border-radius: var(--moller-border-radius-small);
      bottom: calc(100% - 22px);
      left: 35px;
      background-image: linear-gradient(
        -45deg,
        transparent 50%,
        var(
            ${props.$isDealerMoller
              ? "--moller-color-primary-container"
              : "--moller-color-info-container"}
          )
          50%
      );
      transform: rotate(45deg);
    }
  `}
`;

const Title = styled(Subtitle)`
  @media screen and (max-width: ${breakpointTablet}) {
    font-size: var(--moller-typeface-size-base);
  }
`;

const Link = styled.a`
  color: var(--moller-color-on-primary-container);
`;

const Description = styled.p`
  margin: var(--moller-spacing-base) 0 var(--moller-spacing-s);

  @media screen and (max-width: ${breakpointTablet}) {
    font-size: var(--moller-typeface-size-xs);
  }
`;

const ButtonWrapper = styled.div`
  > a {
    border-color: var(--moller-color-on-primary-container);
    width: 100%;
    color: var(--moller-color-on-primary-container);
  }
`;

export const WishesValuationCard = ({
  context,
  isDealerMoller,
  minified = false,
}: {
  context: "car" | "overview";
  isDealerMoller: boolean;
  minified?: boolean;
}) => {
  const valuationUrl = getValuationUrl();
  const valuationUrlWithReferral = `${valuationUrl}/?src=bruktbilTradeIn`;

  return (
    <>
      {minified ? (
        <MinifiedCard $isDealerMoller={isDealerMoller}>
          {isDealerMoller ? (
            <Link href={valuationUrlWithReferral} target="_blank">
              Ønsker du å få en mer nøyaktig verdivurdering av bilen din?
            </Link>
          ) : (
            <p>Kontakt forhandler for å få en verdivurdering av bilen din.</p>
          )}
        </MinifiedCard>
      ) : (
        <Card $isDealerMoller={isDealerMoller}>
          <Title>Ønsker du en mer nøyaktig verdivurdering?</Title>
          <Description>
            {isDealerMoller
              ? "Fyll ut skjemaet og få en uforpliktende verdivurdering av våre eksperter på bruktbil."
              : "Kontakt forhandler for å få en verdivurdering av bilen din."}
          </Description>
          {isDealerMoller && (
            <ButtonWrapper>
              <GhostButton
                $as="a"
                href={valuationUrlWithReferral}
                target="_blank"
                onClick={() =>
                  datadogRum.addAction(
                    `Clicks link to open valuation form in ${context} page ${
                      minified ? "card" : "modal"
                    }`,
                  )
                }
              >
                Få en verdivurdering
                <Icon icon="external" />
              </GhostButton>
            </ButtonWrapper>
          )}
        </Card>
      )}
    </>
  );
};
