"use client";

import { styled } from "styled-components";
import { breakpointDesktop } from "@moller/design-system";

export const FilterAndCarsWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-auto-flow: row;
  margin-top: var(--moller-spacing-m);

  @media screen and (min-width: ${breakpointDesktop}) {
    grid-template-columns: fit-content(296px) auto;
    margin-top: var(--moller-spacing-3xl);
    gap: var(--moller-spacing-xl);
  }
`;
