import { Icon, Subtitle } from "@moller/design-system";
import React from "react";
import { ActionCard } from "@moller/design-system";
import { styled } from "styled-components";
import { CounterBadge } from "@/components/filters/CounterBadge";

const ItemCard = styled(ActionCard)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: solid 1px;
  border-bottom-color: var(--moller-color-outline);
  background-color: transparent;
  color: var(--moller-color-on-background);
  box-shadow: unset;
  border-radius: unset;
`;

const EndSection = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--moller-spacing-s);
`;

interface Props {
  label: string;
  children?: React.ReactNode;
  onClick: () => void;
  chosenCount?: number;
}
export const FilterMenuItem = ({ label, onClick, chosenCount }: Props) => {
  return (
    <ItemCard onClick={onClick}>
      <Subtitle>{label}</Subtitle>
      <EndSection>
        {!!chosenCount && <CounterBadge value={chosenCount.toString()} />}
        <Icon className="chevron_right" icon="chevron_right" />
      </EndSection>
    </ItemCard>
  );
};
