import { Fuel } from "../data/bruktbilsalgBackend/types/car.types";

export const translateFuel = (fuel: Fuel) => {
  switch (fuel) {
    case "Diesel":
      return "Diesel";
    case "Gasolin":
      return "Bensin";
    case "GasolinAndElectricity":
      return "Bensin/Elektrisk";
    case "DieselAndElectricity":
      return "Diesel/Elektrisk";
    case "Electricity":
      return "Elektrisk";
  }
};
