import {
  BrandWithModelsFilter,
  CheckboxFilter,
  DepartmentsFilter,
  EquipmentFilter,
  FuelFilter,
  SalesTypeFilter,
  RangeFilter,
} from "../types/filters.types";
import {
  filterQueryKeys,
  RangeFilterQueryKeys,
} from "../types/filtersQuery.types";
import { ReadonlyURLSearchParams } from "next/navigation";

export const getQueryParamsFromRangeFilter = (
  rangeFilter: RangeFilter | undefined,
  startRangeQueryKey: string,
  endRangeQueryKey: string,
) => {
  if (!rangeFilter) return "";

  const startRangeQuery = rangeFilter.startRange
    ? `&${startRangeQueryKey}=${rangeFilter.startRange}`
    : "";
  const endRangeQuery = rangeFilter.endRange
    ? `&${endRangeQueryKey}=${rangeFilter.endRange}`
    : "";
  return startRangeQuery + endRangeQuery;
};

export const getQueryParamsFromCheckboxFilters = (
  checkboxFilters: CheckboxFilter[] | undefined,
  queryKey: string,
) => {
  return checkboxFilters
    ? checkboxFilters
        .map((filter) => {
          if (filter.isActive) {
            return `&${queryKey}=${filter.key}`;
          }
        })
        .join("")
    : "";
};

const getBrandsQueryString = (
  brandsWithModels: BrandWithModelsFilter[] | undefined,
  queryKey: string,
) => {
  if (!brandsWithModels) {
    return "";
  }

  // If we filter on any models, then we don't want the `brands=` param for that brand, only `models=`
  const brandsQuery = brandsWithModels
    .filter((b) => b.isActive && !b.models.find((m) => m.isActive))
    .map((b) => `${queryKey}=${b.key}`)
    .join("&");

  return `${brandsQuery && "&"}${brandsQuery}`;
};

const getModelsQueryString = (
  brandsWithModels: BrandWithModelsFilter[] | undefined,
  queryKey: string,
) => {
  if (!brandsWithModels) {
    return "";
  }

  const modelsQuery = brandsWithModels
    .filter((b) => b.isActive)
    .flatMap((b) => b.models.filter((m) => m.isActive))
    .map((m) => `${queryKey}=${m.key}`)
    .join("&");

  return `${modelsQuery && "&"}${modelsQuery}`;
};

export const activateFuelFromQuery = (
  fuel: FuelFilter[],
  searchParams: ReadonlyURLSearchParams,
) => {
  const fuelQueryParams = searchParams.getAll(filterQueryKeys.fuelKey);

  return fuel.map((f) => {
    if (fuelQueryParams.includes(f.key?.toString())) f.isActive = true;

    return f;
  });
};

export const activateEquipmentFromQuery = (
  equipment: EquipmentFilter[],
  searchParams: ReadonlyURLSearchParams,
) => {
  const equipmentQueryParams = searchParams.getAll(
    filterQueryKeys.equipmentKey,
  );

  return equipment.map((f) => {
    if (equipmentQueryParams.includes(f.key?.toString())) f.isActive = true;
    return f;
  });
};

export const activateDepartmentsFromQuery = (
  departments: DepartmentsFilter[],
  searchParams: ReadonlyURLSearchParams,
) => {
  const departmentsQueryParams = searchParams.getAll(
    filterQueryKeys.departmentsKey,
  );

  return departments.map((f) => {
    if (departmentsQueryParams.includes(f.key?.toString())) f.isActive = true;

    return f;
  });
};

export const activateSalesTypesFromQuery = (
  salesTypes: SalesTypeFilter[],
  searchParams: ReadonlyURLSearchParams,
) => {
  const salesTypesQueryParams = searchParams.getAll(
    filterQueryKeys.salesTypesKey,
  );

  return salesTypes.map((f) => {
    if (salesTypesQueryParams.includes(f.key?.toString())) f.isActive = true;

    return f;
  });
};

export const setRangeFilterFromQuery = (
  filterStartRangeQueryKey: keyof RangeFilterQueryKeys,
  filterEndRangeQueryKey: keyof RangeFilterQueryKeys,
  searchParams: ReadonlyURLSearchParams,
) => {
  const filterStartRangeFromQuery = searchParams.get(
    filterQueryKeys[filterStartRangeQueryKey],
  );
  const filterEndRangeFromQuery = searchParams.get(
    filterQueryKeys[filterEndRangeQueryKey],
  );

  const rangeFilter: RangeFilter = {
    startRange: Number(filterStartRangeFromQuery),
    endRange: Number(filterEndRangeFromQuery),
  };
  return rangeFilter;
};

export const activateBrandsWithModelsFromQuery = (
  brandsWithModels: BrandWithModelsFilter[],
  searchParams: ReadonlyURLSearchParams,
) => {
  const activeBrands = searchParams.getAll(filterQueryKeys.brandsKey);
  const activeModels = searchParams.getAll(filterQueryKeys.modelsKey);

  return brandsWithModels.map((b) => {
    const activeBrandModel = b.models.find((m) => activeModels.includes(m.key));
    const activeBrand =
      activeBrandModel || activeBrands.find((ab) => ab === b.key?.toString());

    if (activeBrand) {
      b.isActive = true;

      b.models.forEach((m) => {
        m.isActive = activeModels.includes(m.key);
      });
    }
    return b;
  });
};

export const getQueryParamsFromFilters = (
  brandsWithModels: BrandWithModelsFilter[] | undefined,
  fuelFilter: FuelFilter[] | undefined,
  priceRange: RangeFilter | undefined,
  modelYearRange: RangeFilter | undefined,
  mileageRange: RangeFilter | undefined,
  departments: DepartmentsFilter[] | undefined,
  equipmentFilter: EquipmentFilter[] | undefined,
  salesTypeFilter: SalesTypeFilter[] | undefined,
) => {
  const brandsQuery = getBrandsQueryString(
    brandsWithModels,
    filterQueryKeys.brandsKey,
  );

  const modelsQuery = getModelsQueryString(
    brandsWithModels,
    filterQueryKeys.modelsKey,
  );

  const fuelQuery = getQueryParamsFromCheckboxFilters(
    fuelFilter,
    filterQueryKeys.fuelKey,
  );

  const priceQuery = getQueryParamsFromRangeFilter(
    priceRange,
    filterQueryKeys.priceStartRangeKey,
    filterQueryKeys.priceEndRangeKey,
  );

  const modelYearQuery = getQueryParamsFromRangeFilter(
    modelYearRange,
    filterQueryKeys.modelYearStartRangeKey,
    filterQueryKeys.modelYearEndRangeKey,
  );

  const mileageQuery = getQueryParamsFromRangeFilter(
    mileageRange,
    filterQueryKeys.mileageStartRangeKey,
    filterQueryKeys.mileageEndRangeKey,
  );

  const departmentQuery = getQueryParamsFromCheckboxFilters(
    departments,
    filterQueryKeys.departmentsKey,
  );

  const equipmentQuery = getQueryParamsFromCheckboxFilters(
    equipmentFilter,
    filterQueryKeys.equipmentKey,
  );

  const salesTypesQuery = getQueryParamsFromCheckboxFilters(
    salesTypeFilter,
    filterQueryKeys.salesTypesKey,
  );

  return (
    brandsQuery +
    modelsQuery +
    fuelQuery +
    priceQuery +
    modelYearQuery +
    mileageQuery +
    departmentQuery +
    equipmentQuery +
    salesTypesQuery
  );
};
