"use client";

import { css, styled } from "styled-components";
import { IconButton } from "@moller/design-system";
import { PropsWithChildren, useEffect, useState } from "react";

const Wrapper = styled.div<{
  $isCollapsible: boolean;
  $isCollapsed: boolean;
  $contentHeight: string;
  $collapsedHeight: string;
  $shouldOverrideContentHeight: boolean;
}>`
  ${(props) => css`
    position: relative;
    max-height: ${props.$isCollapsed
      ? `${props.$collapsedHeight}px`
      : props.$shouldOverrideContentHeight
        ? "auto"
        : `${props.$contentHeight}px`};
    overflow: hidden;
    height: auto;
    transition: max-height 300ms ease;
    ${props.$isCollapsible &&
    !props.$isCollapsed &&
    "margin-bottom: var(--moller-spacing-s);"}
  `}
`;

const Gradient = styled.div<{ $backgroundColorVariable: string }>`
  ${(props) => css`
    height: var(--moller-size-xxl);
    width: 100%;
    position: absolute;
    bottom: 0;
    background: linear-gradient(
      0deg,
      var(${props.$backgroundColorVariable}) 0%,
      transparent 150%
    );
  `}
`;

export const Collapse = ({
  children,
  id,
  className,
  backgroundColorVariable,
  collapsedHeight,
  buttonLabel,
  showCollapsedOverlay = true,
  shouldOverrideContentHeight = false,
  shouldOpenOnContentClick = false,
  shouldOpenByDefault = false,
}: PropsWithChildren<{
  id: string;
  className?: string;
  backgroundColorVariable: string;
  collapsedHeight: string;
  buttonLabel: { expand: string; collapse: string };
  showCollapsedOverlay?: boolean;
  shouldOverrideContentHeight?: boolean;
  shouldOpenOnContentClick?: boolean;
  shouldOpenByDefault?: boolean;
}>) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isCollapsible, setContentIsCollapsible] = useState(false);
  const [contentHeight, setContentHeight] = useState("");

  useEffect(() => {
    const elementHeight = document.getElementById(id)?.offsetHeight;
    if (elementHeight && elementHeight > Number(collapsedHeight)) {
      setContentIsCollapsible(true);
      setContentHeight((elementHeight + 50).toString()); // add some room for dynamic content, such as department filter
    }
  }, [collapsedHeight, id]);

  useEffect(() => {
    if (shouldOpenByDefault) {
      setIsCollapsed(false);
    }
  }, [shouldOpenByDefault]);

  return (
    <div className={className}>
      <Wrapper
        $isCollapsible={isCollapsible}
        $isCollapsed={isCollapsed}
        $contentHeight={contentHeight || ""}
        $collapsedHeight={collapsedHeight}
        $shouldOverrideContentHeight={shouldOverrideContentHeight}
      >
        <div
          id={id}
          onClick={() => shouldOpenOnContentClick && setIsCollapsed(false)}
        >
          {children}
        </div>
        {isCollapsible && isCollapsed && showCollapsedOverlay && (
          <Gradient $backgroundColorVariable={backgroundColorVariable} />
        )}
      </Wrapper>
      {isCollapsible && (
        <IconButton
          icon={isCollapsed ? "expand_more" : "expand_less"}
          label={isCollapsed ? buttonLabel.expand : buttonLabel.collapse}
          onClick={() => setIsCollapsed(!isCollapsed)}
          showLabel="left"
        />
      )}
    </div>
  );
};
