import { LabelText } from "@moller/design-system";
import React from "react";
import { styled } from "styled-components";

const DepartmentDiv = styled.div`
  display: flex;
  align-items: center;
  gap: var(--moller-spacing-base);
`;

const Department = styled(LabelText)`
  font-family: "Moller", serif;
  font-size: 11px;
  font-weight: 200;
`;

type props = {
  departmentName: string;
};

const DepartmentSection = ({ departmentName }: props) => {
  return (
    <DepartmentDiv>
      <Department>{departmentName}</Department>
    </DepartmentDiv>
  );
};

export default DepartmentSection;
