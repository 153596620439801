"use client";

import { css, styled } from "styled-components";
import { breakpointTablet, Description, Subtitle } from "@moller/design-system";
import { CampaignBadge } from "./campaignBadge";
import { DealerCampaign } from "./campaign.types";

const Title = styled(Subtitle)`
  @media screen and (max-width: ${breakpointTablet}) {
    padding-top: var(--moller-spacing-xs);
  }
`;

const Body = styled.p`
  padding-top: var(--moller-spacing-base);

  @media screen and (max-width: ${breakpointTablet}) {
    padding-top: var(--moller-spacing-xs);
  }
`;

const Disclaimer = styled(Description)`
  color: var(--moller-color-on-disabled);
  padding-top: var(--moller-spacing-s);

  @media screen and (max-width: ${breakpointTablet}) {
    padding-top: var(--moller-spacing-xs);
  }
`;

const BadgeWrapper = styled.div<{ $hideOnMobile?: boolean }>`
  ${(props) => css`
    margin-bottom: var(--moller-spacing-s);

    @media screen and (max-width: ${breakpointTablet}) {
      display: ${props.$hideOnMobile ? "none" : "flex"};
    }
  `}
`;

export const CampaignContent = ({
  campaign,
  hideBadgeOnMobile,
  departmentSlug,
}: {
  campaign: DealerCampaign;
  hideBadgeOnMobile?: boolean;
  departmentSlug?: string;
}) => {
  return (
    <div>
      <BadgeWrapper $hideOnMobile={hideBadgeOnMobile}>
        <CampaignBadge dealerGroupName={departmentSlug || ""} />
      </BadgeWrapper>
      <Title>{campaign.heading}</Title>
      <Body>{campaign.body}</Body>
      <Disclaimer>{campaign.disclaimer}</Disclaimer>
    </div>
  );
};
