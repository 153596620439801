import {
  CheckboxFilter,
  RangeFilter,
} from "@/components/filters/types/filters.types";

export const getCountFromCheckFilter = (
  filterType: CheckboxFilter[] | undefined,
) => filterType?.filter((f) => f.isActive).length || 0;

export const getCountFromRangeFilter = (filterType: RangeFilter) =>
  filterType.startRange !== 0 || filterType.endRange !== 0 ? 1 : 0;
